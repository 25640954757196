@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100vh;
  overflow: hidden;
  touch-action: none;
  overscroll-behavior-x: none;
  /* Prevent mobile vertical scrolling */
  will-change: transform;
  /* Improves performance */
  transform: translateX(0px);

  margin: 0;
  font-family: 'Inter', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  overflow-x: auto;
  /* Enable horizontal scrolling */
  overflow-y: hidden;
  position: relative;
}

.blur-sm {
  -webkit-filter: blur(4px);
  /* Add WebKit prefix for Safari */
  filter: blur(4px);
}

.blur-none {
  -webkit-filter: none;
  /* Add WebKit prefix for Safari */
  filter: none;
}

/* SCROLLBAR */

/* Scrollbar width and height */
::-webkit-scrollbar {
  height: 6px;
  /* Horizontal scrollbar height */
}

/* Scrollbar track */
::-webkit-scrollbar-track {
  background: #101720;
}

/* Scrollbar thumb */
::-webkit-scrollbar-thumb {
  background: #468BB1;
  /* Line Blue */
  border-radius: 50px;
}

/* Scrollbar thumb hover effect */
::-webkit-scrollbar-thumb:hover {
  background: #D9D9D9;
  /* Slightly darker shade of Line Blue for hover effect */
}